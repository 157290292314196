import React from 'react';
import Link from 'next/link';
import {GetOutletsQuery, GetOutletsWithMartQuery} from '@graphql/generated/graphql';
import extractOutlet from '@lib/helpers/outlet-name-area';
import openingHourFormatter from '@lib/helpers/opening-hour-formatter';

interface IOutletHeaderProps {
  outlets: GetOutletsQuery['outlets'] | GetOutletsWithMartQuery['outletsWithMart'];
  href: string;
}

const OutletHeader: React.FC<IOutletHeaderProps> = ({outlets, href}) => {
  return (
    <div className="bg-bgColor paddingHorizontal grid grid-cols-1 gap-3">
      {outlets.map(({id, image, name, friendlyPath, distance, open, close}) => {
        const {outletName, outletArea} = extractOutlet(name);
        return (
          <Link key={id} href={`${href}${friendlyPath}`} passHref>
            <a className="mb-2">
              <img src={image ?? '/image-placeholder.jpg'} className="w-full object-cover h-28 rounded-2xl mb-3" />
              <p className="poppins-semibold mb-0.5">{outletName}</p>
              {outletArea && <p className="poppins-semibold mb-0.5 text-primary">{outletArea}</p>}
              <div className="mt-1 flex flex-row items-center">
                {distance !== null && (
                  <div className="flex flex-row justify-start items-start mr-2">
                    <div className="mt-0.5">
                      <svg width="10" height="14" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6 0C9.3135 0 12 2.6865 12 6C12 7.30475 11.5722 8.502 10.8612 9.4825C10.8485 9.506 10.8465 9.53225 10.832 9.55475L6.832 15.5548C6.6465 15.833 6.334 16 6 16C5.666 16 5.3535 15.833 5.168 15.5548L1.168 9.55475C1.15325 9.53225 1.1515 9.506 1.13875 9.4825C0.42775 8.502 0 7.30475 0 6C0 2.6865 2.6865 0 6 0ZM6 8C7.1045 8 8 7.1045 8 6C8 4.8955 7.1045 4 6 4C4.8955 4 4 4.8955 4 6C4 7.1045 4.8955 8 6 8Z"
                          fill="#b7b7b7"
                        />
                      </svg>
                    </div>
                    <p className="poppins-regular font-sm ml-1.5">{distance.toFixed(2)} km</p>
                  </div>
                )}

                <div key={outletName} className="flex flex-row justify-start items-start">
                  <div className="mt-0.5">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1 7.5C1 8.35359 1.16813 9.19883 1.49478 9.98744C1.82144 10.7761 2.30022 11.4926 2.90381 12.0962C3.50739 12.6998 4.22394 13.1786 5.01256 13.5052C5.80117 13.8319 6.64641 14 7.5 14C8.35359 14 9.19883 13.8319 9.98744 13.5052C10.7761 13.1786 11.4926 12.6998 12.0962 12.0962C12.6998 11.4926 13.1786 10.7761 13.5052 9.98744C13.8319 9.19883 14 8.35359 14 7.5C14 5.77609 13.3152 4.12279 12.0962 2.90381C10.8772 1.68482 9.22391 1 7.5 1C5.77609 1 4.12279 1.68482 2.90381 2.90381C1.68482 4.12279 1 5.77609 1 7.5Z"
                        stroke="#B7B7B7"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 3.88892V7.50003L9.66667 9.66669"
                        stroke="#B7B7B7"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <p className="poppins-regular font-sm ml-1.5">
                    {openingHourFormatter(open)} - {openingHourFormatter(close)}
                  </p>
                </div>
              </div>
            </a>
          </Link>
        );
      })}
    </div>
  );
};

export default OutletHeader;
