import React from 'react';
import OutletHeader from '@components/Outlet/OutletHeader';
import NoStoreCard from '@components/Store/NoStoreCard';
import {useGeolocation} from 'context/geolocationContext';
import {useGetOutletsQuery} from '@graphql/generated/graphql';
import {Spinner} from '..';

interface ISearchOutletsProps {}

const SearchOutlets: React.FC<ISearchOutletsProps> = () => {
  const {latitude, longitude} = useGeolocation();

  const {data} = useGetOutletsQuery({
    variables: {latitude, longitude},
  });

  if (!data) {
    return (
      <div className="paddingHorizontalSmall flex flex-col gap-2 mt-5">
        <Spinner />
      </div>
    );
  }

  return <div>{!data.outlets.length ? <NoStoreCard /> : <OutletHeader outlets={data.outlets} href="/outlets/" />}</div>;
};

export default SearchOutlets;
