import React, {useEffect, useReducer, useRef, useState} from 'react';
import {NoticeImageFragment} from '@graphql/generated/graphql';
import {usePersistentStore} from 'stores';

interface INoticeModalProps {
  data: NoticeImageFragment | undefined;
}

const NoticeModal = ({data}: INoticeModalProps) => {
  const [fadeIn, setFadeIn] = useState(false);
  const [show, toggleShow] = useReducer(prev => {
    document.body.style.overflow = prev ? 'scroll' : 'hidden';
    return !prev;
  }, false);

  const showPopUp = usePersistentStore(s => s.showPopUp);
  const setPopUpShown = usePersistentStore(s => s.setPopUpShown);

  const name = data?.name;
  const image = data?.image;

  useEffect(() => {
    if (name && !showPopUp[name]) {
      setPopUpShown(name);
      toggleShow();
    }
  }, [name, showPopUp, setPopUpShown, toggleShow]);

  useEffect(() => {
    if (!show) return;
    const timeout = setTimeout(() => setFadeIn(true), 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [show]);

  if (!show) return null;

  return (
    <section
      className={`fixed inset-0 max-w-md mx-auto z-90 flex justify-center items-center p-2 transition-all ease-in duration-300 ${
        fadeIn ? 'opacity-100' : 'opacity-0'
      }`}>
      <div className="absolute inset-0 bg-black opacity-75"></div>
      <div className="relative bg-white rounded-md mb-20 sm:mb-14 w-full">
        <div className="absolute -top-10 right-2.5">
          <div onClick={toggleShow}>{CLOSE_BTN_SVG}</div>
        </div>
        <div className="rounded-md overflow-hidden">
          <img src={image} alt={name} className="w-full aspect-square object-cover" />
        </div>
      </div>
    </section>
  );
};

export default NoticeModal;

const CLOSE_BTN_SVG = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="transparent" viewBox="0 0 24 24" stroke="currentColor" className="h-8 w-8 cursor-pointer text-white">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
);
